export default {
	getImageUrl: () => item => {
		if (item?.image) {
			const { image } = item;

			if (image.includes("http")) {
				return image;
			} else if (image.includes("/services/")) {
				return `https://sinao.app${image}`;
			} else {
				return `https://sinao-prod-storage.s3.eu-west-3.amazonaws.com/${image}`;
			}
		}
	}
};
