<template lang="html">
	<button class="s-button" :class="[typeClass, isLarge]" :disabled="disabled" @click="click">
		<span class="button_text">
			<vs-icon v-if="icon && !iconAfter" :icon="icon" :icon-pack="iconPack" :color="iconColor" />
			<slot></slot>
			<vs-icon v-if="icon && iconAfter" :icon="icon" :icon-pack="iconPack" />
		</span>
	</button>
</template>

<script lang="js">

	export default {
		name: 'SButton',
		props: {
			type: String,
			icon: String,
			iconPack: {
				type: String,
				default: 'feather'
			},
			iconAfter: {
				type: [Boolean, String],
				default: false
			},
			href: String,
			to: {
				default: '',
				type: [String, Object]
			},
			large: {
				type: [Boolean, String],
				default: false
			},
			disabled: {
				type: [Boolean, String],
				default: false
			}
		},
		data () {
			return {

			};
		},
		computed: {
			typeClass() {
				return this.type ? `s-button-${ this.type }` : '';
			},
			isLarge() {
				return this.large ? 's-button-large' : '';
			},
			iconColor() {
				return this.type ? '' : 'white';
			}
		},
		methods: {
			click() {
				this.$emit('click');

				if (this.href) {
					window.location.href = this.href;
				}
				else if (this.to) {
					this.$emit('click');
					this.$router.push(this.to);
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.s-button {
		background-color: var(--link);
		padding: 0.8rem 2rem;
		border: none;
		cursor: pointer;
		transition: background-color 400ms ease-in-out, box-shadow 400ms ease-in-out, color 400ms ease-in-out;
		letter-spacing: 1px;
		color: var(--white);
		font-size: 14px;
		font-weight: 500;
		border-radius: 999px;
	}
	.s-button:hover {
		background-color: var(--link-hover);
	}

	.s-button-secondary {
		background-color: #137ec8;
	}
	.s-button-large {
		padding: 1.2rem 1.5rem;
	}

	.s-button-border {
		background-color: var(--background);
		border: 1px solid var(--border);
		color: var(--black);

		margin: 0px !important;
	}
	.s-button-border:hover {
		background-color: transparent;
	}
	.s-button-border-small {
		background-color: var(--background);
		border: 1px solid var(--border);
		color: var(--black);

		margin: 0px !important;
		padding: 1rem !important;
	}
	.s-button-border-small:hover {
		background-color: var(--border);
	}

	.s-button-border-small-danger {
		background-color: transparent;
		border: 1px solid var(--danger);
		color: var(--danger);

		margin: 0px !important;
		padding: 1rem !important;
	}
	.s-button-border-small-danger:hover {
		background-color: var(--danger-hover);
	}

	.s-button-success {
		background-color: var(--success) !important;
	}
	.s-button-success:hover {
		background-color: var(--success-hover) !important;
	}
</style>
