<template>
	<div id="app">
		<div class="layout--main" :class="[navbarClasses, { 'app-page': isAppPage }]">
			<div id="content-overlay" />

			<vs-popup class="holamundo" title="Oops !" :active.sync="showError">
				<p>
					Une erreur est survenue, essayez de rafraichir la page. Si le problème persiste merci de nous en
					faire part.
				</p>
				<vs-collapse>
					<vs-collapse-item>
						<div slot="header">Plus d'informations</div>
						<p class="whitespace-pre-line">
							Code d'erreur :
							{{ error === null ? "" : error.error.request.status }} Message :
							{{ error === null ? "" : error.error.message }} URL de l'appel :
							{{ error === null ? "" : error.error.request.responseURL }}
						</p>
					</vs-collapse-item>
				</vs-collapse>
				<vs-button @click="reload">Rafraichir</vs-button>
			</vs-popup>

			<div class="content-wrapper">
				<the-navbar />

				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import themeConfig from "@/../themeConfig.js";
	import TheNavbar from "./layouts/components/TheNavbar";

	export default {
		components: {
			TheNavbar
		},
		data() {
			return {
				navbarType: themeConfig.navbarType || "sticky",
				navbarColor: themeConfig.navbarColor || "#fff",
				routerTransition: themeConfig.routerTransition || "none",
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				disableCustomizer: themeConfig.disableCustomizer,
				error: null,
				showError: false
			};
		},
		computed: {
			isAppPage() {
				if (this.$route.path.includes("/apps/")) {
					return true;
				} else {
					return false;
				}
			},
			navbarClasses() {
				return {
					"navbar-hidden": this.navbarType == "hidden",
					"navbar-sticky": this.navbarType == "sticky",
					"navbar-static": this.navbarType == "static",
					"navbar-floating": this.navbarType == "floating"
				};
			}
		},
		mounted() {
			document.addEventListener("apiError", error => {
				console.log(error);

				this.error = error;
				this.showError = true;
			});
		},
		methods: {
			reload() {
				location.reload();
			}
		}
	};
</script>

<style>
	#app {
		background-color: var(--background);
	}
	.content-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	.layout--full-page {
		flex-grow: 5;
		position: relative;
	}
	.layout--middle {
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
	}
	.vs-component.vs-button:disabled {
		opacity: 1;
		filter: brightness(175%) grayscale(50%);
	}
	.vs-component.vs-button:hover {
		background-color: #137ec8;
	}
</style>
